<template>
    <div >
        <div class="manage-box"  >
            <div class="manage-left" v-loading="leftLoading">
                <div class="padding-sm" style="overflow:hidden;">
                    <el-button class="fl-r" type="primary" size="mini" @click="setClass">新增课时</el-button>
                </div>

                <div>
                    <div class=" table-header">
                        <div class="fl-l w26">名称</div>
                        <div class="fl-l w26">排序</div>
                        <div class="fl-l w10">概要</div>
                        <div class="fl-l w10">状态</div>
                        <div class="fl-l w26">操作</div>
                        <div class="clear"></div>
                    </div>
                    <div class="section border-s padding-sm" v-for="item in classList" :key="item.id">
                        <div class="fl-l w26">
							<i v-if="item.type === 1" class="circle el-icon-video-camera-solid"></i>
							<i v-else-if="item.type === 2" class="circle el-icon-picture"></i>
                            <i v-else-if="item.type === 3" class="circle el-icon-folder-opened"></i>
							<i v-else-if="item.type === 4" class="el-icon-edit-outline"></i>
							<i v-else-if="item.type === 5" class="el-icon-headset"></i>
							<i v-else-if="item.type === 6" class="el-icon-edit"></i>
							<i v-else-if="item.type === 7" class="el-icon-reading"></i>
							<i v-else-if="item.type === 8" class="el-icon-edit"></i>
							<i v-else-if="item.type === 9" class="el-icon-edit"></i>
							<i v-else-if="item.type === 10" class="el-icon-microphone"></i>
                            {{item.name}}</div>
                        <div class="fl-l w26">
                            <el-input-number size="mini" v-model="item.sort" @change="changeSort(item)"></el-input-number>
                        </div>
                        <div class="fl-l w10">
                            <div v-if="item.type === 1">
                                <p>
                                    视频大小:【{{parseFloat((item.size/1024)/1024).toFixed(2) + 'M'}}】
                                </p>
                                <p style="margin-top: 5px">
                                    视频时长：【{{s_to_hs(item.duration)}}】
                                </p>
                            </div>
                            <p v-else-if="item.type === 2"> 图文</p>
							<p v-else-if="item.type === 3"> 题库</p>
							<p v-else-if="item.type === 4"> 批注</p>
							<p v-else-if="item.type === 5"> 音频</p>
							<p v-else-if="item.type === 6"> 习题</p>
							<p v-else-if="item.type === 7"> 表达</p>
							<p v-else-if="item.type === 8"> 好词好句</p>
							<p v-else-if="item.type === 9"> 知识扩展</p>
							<p v-else-if="item.type === 10"> 朗读</p>
                        </div>
                        <div class="fl-l w10">
                            <span class="state-up"><i></i>已上架</span>
<!--                            <span  class="state-down"><i></i>已下架</span>-->
                        </div>
                        <div class="fl-l w26" >
                            <el-link type="primary" @click="editClass(item)">编辑</el-link>
                            -
                            <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    title="删除后将不可恢复，确定删除吗？"
                                    @confirm="delClass(item)"
                            >
                                <el-link slot="reference" type="primary" >删除</el-link>
                            </el-popconfirm>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
            </div>

            <div class="manage-right" v-loading="rightLoading">
                <div class="padding-sm" style="overflow: hidden">
                    <el-button class="fl-r" type="primary" size="mini" @click="showSetPlanInput">新增计划</el-button>
                </div>
                <div v-if="showSetPlan"  class="padding-sm">
                    <div class="padding-b-sm">
                        <select-images @onSelect="onSelect" @onDelete="onDelete" :image="image"></select-images>
                    </div>
                    <div class="margin-b-sm">
                        <div style="display: flex;align-items: center">
                            <span>背景色：</span>
                            <el-color-picker v-model="plan.background" size="mini"></el-color-picker>
                        </div>
                    </div>
                    <el-input
                            size="mini"
                            placeholder="请输入名称"
                            v-model="plan.name"
                            clearable>
                    </el-input>
                    <el-input
                            class="margin-t-sm"
                            size="mini"
                            placeholder="请输入分组"
                            v-model="plan.group"
                            clearable>
                    </el-input>
                    <el-input
                            size="mini"
                            class="margin-t-sm"
                            placeholder="请输入天数"
                            v-model="plan.day"
                            clearable>
                    </el-input>
                    <el-input
                            class="margin-t-sm"
                            type="textarea"
                            resize="none"
                            :rows="4"
                            placeholder="请输入简介"
                            v-model="plan.intro"
                            clearable>
                    </el-input>
                    <div class="margin-t-sm">
                        <el-button size="mini" type="primary" @click="setPlanSubmit" :loading="setPlanLoading">确定</el-button>
                        <el-button size="mini" @click="hideSetPlanInput">取消</el-button>
                    </div>
                </div>
                <div class="plan-box">
                    <el-tree
                            ref="tree"
                            :indent="5"
                            :data="groups" accordion
                            :props="props"
                            highlight-current
                            :default-expanded-keys="[selectPlanId]"
                            :current-node-key="selectPlanId"
                            @node-click="selectPlan"
                            node-key="id">
                      <span class="custom-tree-node" slot-scope="{ node, data }">
                        <span>{{ node.name }} </span>
                          <span v-if="node.isLeaf === true">[{{data.day}}] </span> {{data.name}}
                          <div v-if="node.isLeaf === true" style="margin-top:5px">
                            <el-link type="primary" :underline="false" @click.stop="editPlan(data)">编辑</el-link>
                            -
                            <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    title="删除后将不可恢复，确定删除吗？"
                                    @confirm="delPlan(data)"
                            >
                                <el-link slot="reference" type="primary" @click.stop>删除</el-link>
                            </el-popconfirm>
                        </div>
                      </span>
                    </el-tree>
<!--                    <div class="plan-item" @click.stop="selectPlan(item.id)" :class="item.id===selectPlanId ? 'current' : ''"  v-for="item in planList" :key="item.id">-->
<!--                        <div class="item-name">-->
<!--                            [{{item.day}}] {{item.name}} -&#45;&#45; {{item.group}}-->
<!--                        </div>-->
<!--                        <div>-->
<!--                            <el-link type="primary" :underline="false" @click.stop="editPlan(item)">编辑</el-link>-->
<!--                            - -->
<!--                            <el-popconfirm-->
<!--                                    icon="el-icon-info"-->
<!--                                    icon-color="red"-->
<!--                                    title="删除后将不可恢复，确定删除吗？"-->
<!--                                    @confirm="delPlan(item)"-->
<!--                            >-->
<!--                                <el-link slot="reference" type="primary" @click.stop>删除</el-link>-->
<!--                            </el-popconfirm>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
            </div>
            <div class="clear"></div>
        </div>

    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import tools from "../../../utils/tools";
    import Cover from "../../../components/cover";
    import EditCover from "../../../components/editCover";
    import SelectImages from "../../../components/selectImages/index";


    export default {
        name: "manage",
        components: {SelectImages},
        data(){
            return{
                props: {
                    label: 'name',
                    children: 'children',
                    isLeaf:'isLeaf'
                },
                imageUrl:config.imageUrl,
                uploadPath:'checkCoursePlan',
                setPlanLoading:false,
                leftLoading:false,
                rightLoading:false,
                manageBoxLoading:false,
                id:0,
                planList:[],
                selectPlanId:0,
                classList:[],
                showSetPlan:false,
                image:'',
                groups: [],
                plan:{
                    id:0,
                    name:'',
                    background:'#f9e572',
                    day:'',
                    check_course_id:0,
                    pic:'',
                    intro:'',
                    group:'主题1'
                }
            };
        },
        watch:{
            selectPlanId(v){
                this.getClass()
            }
        },
        methods:{
            ...mapActions('punch',[
                'getPunchPlan',
                'getPlanDateData',
                'addPlanDate',
            ]),
            ...mapActions('common',['delete','setSort']),
            uploadSuccess(path){
                this.plan.pic = path
                console.log(path)
            },
            showSetPlanInput(){
                this.showSetPlan = true
            },
            s_to_hs(s){
                return tools.sToTime(s)
            },
            onSelect(image){
                this.image =  imageUrl + image.path
                this.plan.pic = image.path
            },
            onDelete(){
                this.image = ''
            },
            hideSetPlanInput(){
                this.plan.id = 0
                this.plan.name = ''
                this.plan.day = ''
                this.plan.pic = ''
                this.image = ''
                this.plan.intro = ''
                this.plan.check_course_id = 0
                this.showSetPlan = false
            },
            editClass(item){
                this.$router.push('/checkCourse/editClass/'+item.id)
            },
            delClass(item){
                console.log(item)
                let data = {
                    model:'CheckCoursePlanClass',
                    id:item.id
                }
                this.delete(data).then(res => {
                    if(res.ret === 0){
                        this.$message.success('删除成功')
                        this.getClass()
                    }
                })
            },
            changeSort(item){
                let data = {
                    id:item.id,
                    sort:item.sort,
                    model:'CheckCoursePlanClassModel'
                }
                this.setSort(data).then(res => {
                    if(res.ret === 0){
                        this.$message.success('排序成功！')
                        this.getClass()
                    }
                })
            },
            async getPlan(){
                this.rightLoading = true
                let groups = []
                let groupName = []
                this.rightLoading = true
                let data = {
                    check_course_id:this.$route.params.id
                }
                this.getPunchPlan(data).then(res => {
                    if(res.data.length > 0){
                        this.planList = res.data
                        if(this.selectPlanId === 0) this.selectPlanId = res.data[0].id
                        res.data.forEach(item => {
                            if(!groupName.includes(item.group)){
                                groupName.push(item.group)
                            }
                        })
                        groupName.forEach(name => {
                            let groupList = []
                            res.data.forEach(item => {
                                if(item.group === name){
                                    item.isLeaf = true
                                    groupList.push(item)
                                }
                            })
                            groups.push({
                                name: name + ' [ '+groupList[0].day+' ~ '+groupList[groupList.length-1].day+' ]',
                                children:groupList,
                            })
                        })

                        this.groups = groups
                        this.$nextTick(() => {
                            this.$refs['tree'].setCurrentKey(this.selectPlanId)
                        })
                    }
                }).finally(()=>{
                    this.rightLoading = false
                })
            },
            getClass(){
                this.leftLoading = true
                let data = {
                    check_course_plan_id:this.selectPlanId
                }
                this.getPlanDateData(data).then(res => {
                    this.classList = res.data
                }).finally(()=>{
                    this.leftLoading = false
                })
            },
            selectPlan(e){
                if(e.id === undefined) return
                this.selectPlanId = e.id
                this.$router.push('/checkCourse/detail/'+this.id+'/manage?planId='+e.id)
                // this.getClass()
            },
            editPlan(item){
                this.plan.id = item.id
                this.plan.name = item.name
                this.plan.day = item.day
                this.plan.pic = item.pic
                if(this.plan.pic) this.image = imageUrl+this.plan.pic
                this.plan.intro = item.intro
                this.plan.group = item.group
                this.plan.background = item.background
                this.plan.check_course_id = item.check_course_id
                this.showSetPlanInput()
            },
            delPlan(item){
                let data = {
                    model:'CheckCoursePlan',
                    id:item.id
                }
                this.delete(data).then(res => {
                    if(res.ret === 0){
                        this.$message.success('删除成功')
                        location.reload()
                        // this.getPlan()
                    }
                })

            },
            setClass(){
                if(this.planList.length === 0){
                    this.$message.error('请先新增计划')
                    return
                }
                this.$router.push('/checkCourse/addClass/'+this.selectPlanId+'/'+this.id)
                console.log()
            },
            setPlanSubmit(){
                console.log(this.plan)
                this.setPlanLoading = true
                this.plan.check_course_id = this.id
                this.addPlanDate(this.plan).then(res => {
                    if(res.ret===0){
                        this.$message.success('操作成功')
                        this.hideSetPlanInput()
                        this.selectPlan(res.data)
                        this.getPlan()
                    }
                }).finally(()=>{
                    this.setPlanLoading = false
                })
            }
        },
        async mounted() {
            if(this.$route.query.planId !== undefined)
            {
                this.selectPlanId = Number(this.$route.query.planId)
                console.log(this.selectPlanId)
            }

            this.id = this.$route.params.id
            await this.getPlan()

        }
    }
</script>

<style >
    .table-header{
        padding:15px;
        background: #fafafa;
    }
    .font-size-12{
        font-size: 12px;
    }
    .circle{
        border:1px solid #ccc;
        padding:3px;
        border-radius: 50%;
        margin-right: 3px;
        font-size: 8px;
    }
    .section{
        font-size: 14px;
        padding: 15px;
        display: flex;
        align-items: center;
    }
    .border-s{
        border-bottom:1px solid #ebebeb
    }
    .w10{
        width: 20%;
    }
    .w26{
        width: 20%;
    }
.manage-left{
    float: left;
    width: 70%;
    height: 100%;
}
    .manage-right{
        border-left: 1px solid #ccc;
        float: right;
        width: 29%;
        height: 700px;
    }
    .plan-box{
        height: 600px;
        overflow: auto;
        padding:10px
    }
    .plan-item{
        padding:10px 5px;
        cursor: pointer;
    }
    .plan-item:hover{
        background: #EBEEF5;
    }
    .current{
        background: #F2F6FC;
    }
    .item-name{
        line-height: 24px;
    }
    .el-tree-node__content{
        height: auto;
        padding:5px 0;
    }
</style>